import React from 'react';
import './Regulatory.css';

const Regulatory = () => {
  const countryList = [
    { id: 1, name: 'US FDA' },
    { id: 2, name: 'PMDA' },
    { id: 3, name: 'HA' },
    { id: 4, name: 'ANVISA' },
    { id: 5, name: 'EMA' },
    { id: 6, name: 'KSA' },
    { id: 7, name: 'UAE' },
    { id: 8, name: 'ANVISA' },
];

  return (
    <>
        <div className='section-vertical-padding'>
            <div className="container">
                <div className="h4 text-center p-5">Regulatory Agencies: </div>
                <div className="row">
                    {countryList.map((ctry) => (
                        <div className="col-md-3">
                            <div className='card text-center' key={ctry.id}>
                                <p className="card-content">{ctry.name}</p>
                            </div>
                        </div>
                    ))}
                    {/* <div className="col-md-3">
                        <div className="list-close">
                            and other ROW countries.  
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </>
)
}

export default Regulatory