import React from 'react';
import './ContactUs.css';
import RequestForm from './RequestForm';

function ContactUs() {
  return (
    <>
      <div className="container section-vertical-padding">
        <div className="row">
          <div className="col-md-6">
            <RequestForm />
          </div>
          <div className="col-md-6">
            <>
              <section id="contact" className="contact-us">
                <h2>Contact Us</h2>
                <p>Regus, Manchester, Trafford Park, Centenary Way, Greater Manchester, M50 1RF, United Kingdom</p>
                <p>Phone: +44 800 7562911 | Mobile: +447587570977</p>
                <p>Email: <a href="mailto:admin@fapharma.co.uk">admin@fapharma.co.uk</a></p>
              </section>
            </>

            <>
              <div className="map-section pt-4">
                <h2 className="map-title">Our Location</h2>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9497.038316401375!2d-2.3268512!3d53.4816916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487baff628316d1d%3A0x50e4b3cfcd437d18!2sRegus%20-%20Manchester%2C%20Trafford%20Park!5e0!3m2!1sen!2sin!4v1729166626328!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Google Map"
                ></iframe>
              </div>
            </>

          </div>
        </div>
      </div>



    </>
  );
}

export default ContactUs;
