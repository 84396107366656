import './App.css';
// import Footer from './components/Footer';
// import Hero from './components/Hero';
// import About from './components/About';
import Services from './components/Services';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Home from './components/Home';
import Footer from './components/Footer';
import Careers from './components/Careers';
import Nav from './components/Nav';

function App() {
  return (
    <div className="App">
      <Nav />
      <Header />
      <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/careers' exact element={<Careers />} />
        <Route path='/services' exact element={<Services />} />
      </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
