import React from 'react';
import './Countries.css';

import AfricaFlag from '../images/flags/africa-flag.png';
import BrazilFlag from '../images/flags/brazil-flag.png';
import ChinaFlag from '../images/flags/china-flag.png';
import JapanFlag from '../images/flags/japan-flag.png';
import RussiaFlag from '../images/flags/russia-flag.png';
import UsaFlag from '../images/flags/usa-flag.png';
import UkFlag from '../images/flags/uk-flag.png';
import EuropeFlag from '../images/flags/europe-flag.png';



const Countries = () => {
    const countryList = [
        { id: 1, name: 'Africa', flag: AfricaFlag },
        { id: 2, name: 'Brazil', flag: BrazilFlag },  
        { id: 3, name: 'China', flag: ChinaFlag },
        { id: 4, name: 'Japan', flag: JapanFlag },
        { id: 5, name: 'Russia', flag: RussiaFlag },
        { id: 6, name: 'USA', flag: UsaFlag },
        { id: 7, name: 'UK', flag: UkFlag },
        { id: 8, name: 'Europe', flag: EuropeFlag },
        
        
              
        
        // { id: 8, name: 'Latin America', flag: 'path/to/latin-america-flag.jpg' },
        // { id: 9, name: 'EMEA', flag: 'path/to/emea-flag.jpg' },        
        
        // { id: 11, name: 'Gulf Countries', flag: 'path/to/gulf-countries-flag.jpg' },
        
    ];

    return (
        <div className='alt-section section-vertical-padding'>
            <div className="container">
                <div className="h4 text-center p-5">Countries we help with</div>
                <div className="row">
                    {countryList.map((ctry) => (
                        <div className="col-md-3" key={ctry.id}>
                            <img src={ctry.flag} alt={ctry.name} className='img-fluid' />       

                                <p className="card-content">{ctry.name}</p>
                            </div>
                        
                    ))}
                    <div className="col-md-12">
                        <div className="list-close">
                            Latin America, EMEA, Gulf and other ROW countries.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countries;
