import React from 'react';
import './ServiceLine.css'; // Import CSS for styling

// Timeline component
const ServiceLine = () => {
  return (
    <section class="timeline">
  <div class="container">
    
    <div class="timeline-item">
      <div class="timeline-img"></div>

      <div class="timeline-content js--fadeInLeft">
        <h4 className="service-title">Drug Substance Services</h4>
        <ul>
                        <li className='service-points'>Authoring CMC Module 3 and Module 2.3 (QOS)</li>
                        <li className='service-points'>DMF – Drug Master File </li>
                        <li className='service-points'>CEP -Certificate of European Pharmacopoeia </li>
                        <li className='service-points'>MF - Japan Master File Registration (DMF)</li>
                        <li className='service-points'>AFM/FMA -Accreditation of Foreign Manufacturer (Japan)</li>
                        <li className='service-points'>Response to health authority questions</li>
                        <li className='service-points'>CMC – Gap analysis</li>
                      </ul>
      </div>
    </div> 

    
     <div class="timeline-item">
      <div class="timeline-img"></div>
      <div class="timeline-content js--fadeInRight">
      <h4 className="service-title">Drug Product Services</h4>
      <ul>
                        <li className='service-points'>Authoring CMC Module 3 and Module 2.3 (QOS)</li>
                        <li className='service-points'>JNDA -Japan – New Drug Application</li>
                        <li className='service-points'>BLA -Biologics License Application</li>
                        <li className='service-points'>MAA – Marketing Authorization Application</li>
                        <li className='service-points'>ANDA - Abbreviated New Drug Application</li>
                        <li className='service-points'>NDA -New Drug Application</li>
                        <li className='service-points'>IND - Investigational New Drug </li>
                        <li className='service-points'>Briefing book</li>
                        <li className='service-points'>Scientific Advisory Meeting</li>
                        <li className='service-points'>CMC Gap Analysis </li>
                      </ul>
      </div>
    </div>   

    
    <div class="timeline-item">
      <div class="timeline-img"></div>

      <div class="timeline-content js--fadeInLeft">
      <h4 className="service-title">Post Approval life cycle maintenance </h4>
      <p className="service-title">FA Pharma has extensive experience in post-approval lifecycle maintenance activities to submit the post approval variations. </p>
      <ul>
                        <li className='service-points'>MCN-Minor Change Notification (Japan)</li>
                        <li className='service-points'>PCN -Partial Change Notification (Japan)</li>
                        <li className='service-points'>CBE-0/CBE-30/PAS, PMCs, Type I/II Variations </li>
                        <li className='service-points'>PACMP – Post Approval Change Management Protocols</li>
                        <li className='service-points'>AFM/FMA -Accreditation of Foreign Manufacturer (Japan)</li>
                        <li className='service-points'>Response to Health Authority Questions </li>
                        <li className='service-points'>Manufacturing Site Transfers</li>
                      </ul>
      </div>
    </div> 

     <div class="timeline-item">
      <div class="timeline-img"></div>
      <div class="timeline-content js--fadeInRight">
      <h4 className="service-title">GMP/Quality Compliance </h4>
                      <p className="service-points">GMP inspection /Audits</p>
                      <p className="service-points">Warehouse, Distribution, Storage of products</p>
                      <p className="service-points">Experience in reviewing the GMP documents/ source documents, analytical method validations protocols, reports, batch manufacturing records (BMR) and manufacturing process validations protocols and reports.</p>
                      
      </div>
    </div>   
      
      



  </div>
</section>

  );
};

export default ServiceLine;
