import React from 'react';
import './Footer.css';
// import { NavLink as Link } from 'react-router-dom';
import CodeFundaLogo from '../images/cf-nav-logo2.png';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} FA Pharma Consulting. All rights reserved.</p>
        <div className="footer-links">
          <a href="#about">About Us</a>
          <a href="#services">Services</a>
          <a href="#contact">Contact Us</a>
          <a href="/careers">Careers</a>
          
        </div>
      </div>
      <div className="footer-socials">
        <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>

        <hr />

                    <p className='text-center text-white'>Crafted by
                        <a target='_blank' rel="noreferrer" href='http://codefunda.co/'>
                            <img src={CodeFundaLogo} alt='CodeFunda.co' height={30} />
                        </a>
                    </p>

      </div>
    </footer>
  );
}

export default Footer;