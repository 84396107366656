import React from 'react';
import CareersForm from './CareersForm';

const Careers = () => {
  return (
    <>
    <div>
      <CareersForm />
    </div>
    </>
  )
}

export default Careers