import React from 'react'
import abtImg from '../images/service44.jpeg';
import './Therapeutic.css';

const Therapeutic = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="about-image">
              <img className='img-fluid' src={abtImg} alt="Pharmaceutical Products" /> {/* Replace with your image URL */}
            </div>
          </div>
          <div className="col-md-8">
          <h2 className="about-title">Therapeutic Area</h2>
            <div className="row">
            <div className="col-md-6">
            <div className="about-content">
              <p className="about-text">
                <ul>
                  <li>Small Molecules</li>
                  <li>Large Molecules</li>
                  <li>Biologics</li>
                  <li>Vaccines Oncology</li>
                  <li>Cardiovascular</li>
                </ul>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-content">
              <p className="about-text">
                <ul>
                  <li>Central Nervous System</li>
                  <li>Infectious</li>
                  <li>Rase Disease Respiratory</li>
                  <li>Immunology</li>
                  <li>Haematology</li>
                </ul>
              </p>
            </div>
          </div>

            </div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Therapeutic