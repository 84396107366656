import React from 'react';
// import { NavLink } from 'react-router-dom';

const Nav = () => {
  return (
    <div>
      {/* <NavLink to=''>Home</NavLink>
      <NavLink to='careers'>careers</NavLink>
      <NavLink to='services'>Services</NavLink> */}
      {/* <NavLink></NavLink> */}
    </div>
    
  )
}

export default Nav