import React from 'react'
import Hero from './Hero';
import AboutUs from './AboutUs';
import ServicesHome from './ServicesHome';
import Clients from './Clients';
import Countries from './Countries';
import ContactUs from './ContactUs';
import Qlife from './Qlife';
import Therapeutic from './Therapeutic';
import Regulatory from './Regulatory';

const Home = () => {
    return (
        <div>
            <>
                <Hero />
                <AboutUs />
                <ServicesHome />
                <Therapeutic/>
                <Regulatory/>
                <Clients />
                <Qlife/>
                <Countries />
                <ContactUs />
            </>
        </div>
    )
}

export default Home