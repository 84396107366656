import React from 'react';
import './ServicesHome.css';
import { NavLink as Link } from 'react-router-dom';

function Services() {
  return (
    <section id="services" className="services section-vertical-padding">
      <h2>Our Services</h2>
      <p>We help pharmaceutical companies to register the products and gain regulatory approvals worldwide.</p>
      <ul>
        <li>Drug Substance Services: <b>CMC Authoring, DMF, CEP, AFM.</b></li>
        <li>Drug Product Services: <b>JNDA, BLA, MAA, NDA, IND.</b></li>
        <li>Post Approval Life Cycle Maintenance</li>
        <li>GMP/Quality Compliance</li>
      </ul>
      <Link to="services" className='btn-new'>know More</Link>
    </section>
  );
}

// const styles = {
//   button: {
//     padding: '10px 20px',
//     backgroundColor: '#007bff',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '16px',
//   },
// };


export default Services;
